<template>
	<div class="noticeAdd">
		<div class="noticeAdd-title">
			<span class="noticeAdd-title-val">公告详情</span>
		</div>
		<div class="noticeAdd-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">公告标题</span>
						<el-input v-model="noticeData.title" placeholder="请输入公告标题" type="text"
							class="common-table-val" />
					</div>
				</div>
			</div>
			<div class="noticeAdd-editor">
				<div class="noticeAdd-editor-label">公告详情</div>
				<quill-editor v-model="noticeData.content" class="noticeAdd-editor-val" ref="QuillEditor"
					:options="editorOption" @change="editorChange">
				</quill-editor>
			</div>
			<div class="noticeAdd-btn">
				<span @click="noticeHandle('confirm')"
					class="noticeAdd-btn-confirm">{{handleType == 'add' ?'立即创建':'确定修改'}}</span>
				<span @click="noticeHandle('cancel')" class="noticeAdd-btn-cancel">取消</span>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		noticeAddPc,
		noticeDetailPc,
		noticeEditPc,
	} from "@/api/pc/notice";
	export default {
		data() {
			return {
				editorOption: {
					placeholder: "请输入公告详情",
					modules: {
						toolbar: {
							container: [
								["bold", "italic", "underline"], // 加粗，斜体，下划线，删除线
								[{
									align: []
								}], // 对齐方式
							],
							handlers: {
								image: value => {
									if (value) {
										document.querySelector(".uploadImage input").click();
										//   console.log(value)
									} else {
										this.quill.format("image", false);
									}
								},
							}
						}
					}
				},
				noticeData: {
					title: "",
					content: ""
				},
				handleType: "",
				noticeId: "",
			}
		},
		mounted() {
			this.handleType = this.$route.query.handleType
			this.noticeId = this.$route.query.noticeId
			if (this.noticeId) {
				this.getNoticeDetail()
			}
		},
		methods: {
			getNoticeDetail() {
				noticeDetailPc(this.noticeId, {
					id: this.noticeId
				}).then((res) => {
					if (res.code == 0) {
						this.noticeData = res.data
					}
				})
			},
			editorChange({
				quill,
				html,
				text
			}) {
				// console.log("editor change!", quill, html, text);
				this.noticeData.content = html
			},
			noticeHandle(type) {
				switch (type) {
					case "confirm":
						let params = JSON.parse(JSON.stringify(this.noticeData))
						if (!params.title) {
							this.$message({
								message: '请输入公告标题~',
								type: 'error',
							});
							return
						}
						if (!params.content) {
							this.$message({
								message: '请输入公告内容~',
								type: 'error',
							});
							return
						}
						let messageText = ""
						if (this.handleType == 'add') {
							messageText = '公告新增成功~'
							noticeAddPc(params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: messageText,
										type: 'success',
									});
									this.$router.go(-1)
								}
							})
						} else {
							messageText = '公告编辑成功~'
							noticeEditPc(this.noticeId, params).then((res) => {
								if (res.code == 0) {
									this.$message({
										message: messageText,
										type: 'success',
									});
									this.$router.go(-1)
								}
							})
						}

						break;
					case "cancel":
						this.$router.go(-1)
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	.noticeAdd-btn {
		display: flex;
		align-items: center;
		margin-top: 30px;

		.noticeAdd-btn-confirm {
			width: 96px;
			height: 36px;
			background: #4392FA;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			cursor: pointer;
			margin-right: 16px;
		}

		.noticeAdd-btn-cancel {
			width: 96px;
			height: 36px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #303133;
			cursor: pointer;
			border: 1px solid #DCDFE6;
		}
	}

	.noticeAdd {
		display: flex;
		flex-direction: column;

		.noticeAdd-title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.noticeAdd-title-val {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #303133;
				margin-bottom: 16px;
				padding-left: 10px;
				border-left: 4px solid #4392FA;
				margin: 16px 0;
			}
		}

		.noticeAdd-content {
			display: flex;
			flex-direction: column;

			.common-table {
				width: 100%;
				border-radius: 2px;
				border-top: 1px solid #DCDFE6;
				border-left: 1px solid #DCDFE6;
				display: flex;
				flex-direction: column;
				margin: 10px 0;

				.common-table-row {
					display: flex;
					align-items: center;

					.common-table-col {
						flex: 1;
						display: flex;
						align-items: stretch;

						.common-table-label {
							min-width: 100px;
							line-height: 36px;
							background: #F6F7F9;
							border-right: 1px solid #DCDFE6;
							border-bottom: 1px solid #DCDFE6;
							box-sizing: border-box;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #606266;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.common-table-val {
							flex: 1;
							box-sizing: border-box;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #303133;
							border-right: 1px solid #DCDFE6;
							border-bottom: 1px solid #DCDFE6;
						}

						.common-table-text {
							flex: 1;
							line-height: 36px;
							box-sizing: border-box;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #303133;
							padding: 0 15px;
							border-right: 1px solid #DCDFE6;
							border-bottom: 1px solid #DCDFE6;
							display: flex;
							align-items: center;
							overflow: hidden;
							display: -webkit-box;
							/*设置为弹性盒子*/
							-webkit-line-clamp: 1;
							/*最多显示3行*/
							overflow: hidden;
							/*超出隐藏*/
							text-overflow: ellipsis;
							/*超出显示为省略号*/
							-webkit-box-orient: vertical;
							word-break: break-all;
						}
					}
				}
			}

			.noticeAdd-editor {
				display: flex;
				flex-direction: column;

				.noticeAdd-editor-label {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 40px;
					color: #606266;
					background: #F6F7F9;
					border: 1px solid #DCDFE6;
					border-bottom: 0;
					border-radius: 2px 2px 0px 0px;
					margin-top: 16px;
					padding: 0 22px;
					box-sizing: border-box;
				}


			}
		}
	}
</style>

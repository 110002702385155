import axios from '@/utils/request'

let Api = {
	noticeList: "/announcementManage/list",
	noticeAdd: "/announcementManage/",
	noticeDetail: "/announcementManage/",
	noticeEdit: "/announcementManage/",
	noticeDelete: "/announcementManage/",
}
// 公告列表
export function noticeListPc(params) {
	return axios({
		method: 'get',
		url: Api.noticeList,
		params: params
	})
}
// 公告新增
export function noticeAddPc(params) {
	return axios({
		method: 'post',
		url: Api.noticeAdd,
		data: params
	})
}
// 公告详情
export function noticeDetailPc(id, params) {
	return axios({
		method: 'get',
		url: Api.noticeDetail + id,
		params: params
	})
}
// 公告编辑
export function noticeEditPc(id, params) {
	return axios({
		method: 'put',
		url: Api.noticeEdit + id,
		data: params
	})
}
// 公告删除
export function noticeDeletePc(id, params) {
	return axios({
		method: 'delete',
		url: Api.noticeDelete + id,
		params: params
	})
}